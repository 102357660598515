import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;


export const acceptTerms = async (userId) => {
    try {
      const response = await axios.post(`${API_URL}/terms-conditions/`, {
        user_id: userId,
      },
      {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    });
      return response.data;
    } catch (error) {
      console.error('Error sending final message:', error);
      throw error;
    }
  };
