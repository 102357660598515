import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import Modal from '../Modal/Modal'; // Import Modal component
import ContactUs from '../ContactUs/ContactUs'; // Import ContactUs component


function Sidebar({ isOpen, toggleSidebar }) {
  const [userImage, setUserImage] = useState('');
  const [isLogoDropdownOpen, setIsLogoDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false); // Modal visibility


  useEffect(() => {
    // Retrieve user image and full name from local storage
    const storedUserImage = localStorage.getItem('userImage');

    if (storedUserImage) {
      setUserImage(storedUserImage);
    }

  }, []); // Empty dependency array to run only once on mount

  const toggleLogoDropdown = () => {
    setIsLogoDropdownOpen(!isLogoDropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleLogout = () => {
    // Clear local storage and perform logout actions
    localStorage.clear();
    window.location.href = '/'; // Redirect to the landing page or login page
  };
  const handleOpenContactModal = () => {
    setIsContactModalVisible(true); // Show ContactUs modal
  };

  const handleCloseContactModal = () => {
    setIsContactModalVisible(false); // Close ContactUs modal
  };

  const handleSendContactMessage = (message) => {
    // Handle the send action for ContactUs form
    console.log('Message sent:', message);
    handleCloseContactModal(); // Close the modal after sending the message
  };

  return (
    <div className="main-container">
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        <img
          src={`${process.env.PUBLIC_URL}/sidebar.svg`}
          alt="Toggle Sidebar"
          className="sidebar-toggle-icon"
        />
      </div>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-header">
          <img
            src={userImage}
            alt="User Profile"
            className="sidebar-profile-image"
            onClick={toggleProfileDropdown}
          />
          {isProfileDropdownOpen && (
            <div className="sidebar-profile-dropdown">
              <ul>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
        <ul>
          <li>
            <a href="/home">Home</a>
          </li>
        </ul>
        <div className="sidebar-footer">
          <img 
            src={`${process.env.PUBLIC_URL}/entrovLogo.png`} 
            alt="Entrov Logo" 
            className="sidebar-logo" 
            onClick={toggleLogoDropdown}
          />
          {isLogoDropdownOpen && (
            <div className="sidebar-dropdown">
              <ul>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/terms-and-conditions">Terms of Use</a></li>
                <li onClick={handleOpenContactModal}>Contact Us</li> {/* Open ContactUs modal */}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Main content will be rendered here */}
      {/* Use the existing Modal component to render Contact Us form */}
      <Modal isVisible={isContactModalVisible} onClose={handleCloseContactModal}>
        <ContactUs
          userName={localStorage.getItem('firstName')} // Retrieve first name from local storage
          onSend={handleSendContactMessage}
        />
      </Modal>
    </div>
  );
}

export default Sidebar;
