import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import SimpleMDE from 'react-simplemde-editor';
import Modal from '../../components/Modal/Modal';
import 'simplemde/dist/simplemde.min.css';
import { fetchProgressReport, saveProgressReport } from '../../services/summarizeService';
import { sendEmail } from '../../services/authService';
import './ProgressSummary.css';

function ProgressSummary() {
  const { goalId } = useParams();
  const location = useLocation();
  const [assistantMessage, setAssistantMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    
    const loadProgressReport = async () => {
      if (userId) {
        setIsLoading(true);
        try {
          // Check if the page has been reloaded
          const isPageReloaded = performance.getEntriesByType('navigation')[0].type === 'reload';
          
          // Pass "false" as a string if the page is reloaded
          const forceUpdate = isPageReloaded ? "false" : "true";
          const startDate = location.state?.startDate || null;
          const data = await fetchProgressReport(userId, goalId, forceUpdate,startDate);
          setAssistantMessage(data.assistant_message);
        } catch (error) {
          console.error('Error loading progress report:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('User ID not found in local storage.');
      }
    };


    
    loadProgressReport();
  }, [userId, goalId, location.state]);

  const handleSave = async () => {
    await saveProgressReport(userId, goalId, assistantMessage);
    setIsEditing(false);
  };

  const handleSendEmail = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const subject = `Progress Report for Goal ${goalId}`;
      await sendEmail(accessToken, email, subject, assistantMessage);
      alert('Email sent successfully');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    }
  };

  return (
    <div className="progress-summary-page">
      <div className="progress-summary-header">
        <h1>Progress Summary</h1>
        <button onClick={() => setIsEditing(!isEditing)} className="edit-button">
          {isEditing ? 'Save' : 'Edit'}
        </button>
      </div>

      <div className="progress-summary-content">
        {isLoading ? (
          <div className="loading-spinner">
            Loading
            <span className="dot dot1">.</span>
            <span className="dot dot2">.</span>
            <span className="dot dot3">.</span>
          </div>
        ) : isEditing ? (
          <SimpleMDE
            value={assistantMessage}
            onChange={setAssistantMessage}
            options={{
              spellChecker: false,
              toolbar: ["bold", "italic", "heading", "|", "quote", "code", "table", "unordered-list", "ordered-list", "|", "preview"],
              status: false,
            }}
          />
        ) : (
          <div className="markdown-viewer">
            <ReactMarkdown>{assistantMessage}</ReactMarkdown>
          </div>
        )}
      </div>

      <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <h2>Send Progress Report</h2>
        <input
          type="email"
          placeholder="Enter recipient email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="email-input"
        />
        <button onClick={handleSendEmail} className="send-button">Send</button>
      </Modal>
    </div>
  );
}

export default ProgressSummary;
