import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { fetchUserGoals } from '../../services/goalService';
import './SummarizeProgress.css';

function SummarizeProgress() {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedRange, setSelectedRange] = useState("All Time"); // Default range
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const loadGoals = async () => {
        try {
          const data = await fetchUserGoals(userId);
          setGoals(data);
        } catch (error) {
          console.error("Failed to load goals:", error);
          setError("Failed to load goals. Please try again.");
        }
      };
      loadGoals();
    } else {
      console.error('User ID not found in local storage.');
    }
  }, []);

  const handleGoalSelect = (event) => {
    setSelectedGoal(event.target.value);
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
  };

  const getStartDateForRange = () => {
    const today = new Date();
    let startDate;

    if (selectedRange === "Last Week") {
      // Get the previous Sunday to Saturday range
      const lastSunday = new Date(today.setDate(today.getDate() - today.getDay() - 7));
      startDate = new Date(lastSunday);
      startDate.setDate(lastSunday.getDate() - lastSunday.getDay());
    } else if (selectedRange === "This Week") {
      // Get the start of this calendar week (Sunday)
      const thisSunday = new Date(today.setDate(today.getDate() - today.getDay()));
      startDate = thisSunday;
    }

    return startDate ? startDate.toISOString().split("T")[0] : null; // Format as "YYYY-MM-DD"
  };

  const handleGenerateUpdate = () => {
    const startDate = getStartDateForRange();
    navigate(`/summary/${selectedGoal}`, { state: { startDate } });
  };

  return (
        <div className="summarize-progress-container">
      <h2>Summarize Progress</h2>

      {error && <p className="error-message">{error}</p>}

      {goals.length > 0 ? (
        <div className="goal-selection">
          <p className="help-text">Select a goal you'd like to summarize progress for.</p>
          <select onChange={handleGoalSelect} value={selectedGoal || ""}>
            <option value="" disabled>Select a goal</option>
            {goals.map((goal) => (
              <option key={goal.goal_id} value={goal.goal_id}>
                {goal.title}
              </option>
            ))}
          </select>

          <p className="help-text">Select the time range you'd like to include updates from.</p>
          <div className="date-range-buttons">
            <button
              className={`date-range-button ${selectedRange === "All Time" ? "active" : ""}`}
              onClick={() => handleRangeSelect("All Time")}
            >
              All Time
            </button>
            <button
              className={`date-range-button ${selectedRange === "Last Week" ? "active" : ""}`}
              onClick={() => handleRangeSelect("Last Week")}
            >
              Last Week
            </button>
            <button
              className={`date-range-button ${selectedRange === "This Week" ? "active" : ""}`}
              onClick={() => handleRangeSelect("This Week")}
            >
              This Week
            </button>
          </div>

          {selectedGoal && (
            <button className="generate-update-button" onClick={handleGenerateUpdate}>
              Generate Update
            </button>
          )}
        </div>
      ) : (
        <p>No active goals found.</p>
      )}
    </div>

  );
}

export default SummarizeProgress;
