import React, { useState, useEffect, useRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { fetchGoogleUserProfile, loginUser } from '../../services/authService';
import { acceptTerms } from '../../services/profileService';
import {createNewGoal,updateGoal} from '../../services/goalService';
import {submitMessage, finalMessage} from '../../services/updatesService';
import Modal from '../../components/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
  const navigate = useNavigate();
  const [typedText, setTypedText] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const [prompt, setPrompt] = useState("What’s a project or goal you’re working on?");
  const [showDateInput, setShowDateInput] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectName, setProjectName] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [progressDescription, setProgressDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [headingVisible, setHeadingVisible] = useState(true); // Track heading visibility
  const [isTyping, setIsTyping] = useState(false); // Track if user started typing in Step 1
  const [goalId, setGoalId] = useState(""); // Add goal_id state
  const [updateId, setUpdateId] = useState("");
  const [assistantMessage, setAssistantMessage] = useState("");
  const stepsRef = useRef([]);
  const [stepInView,setStepInView] = useState([false,false,false]);
  const tryItOutRef = useRef(null);
  const fullText = prompt;

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Login successful. Token response:", tokenResponse);
      try {
        const userProfile = await fetchGoogleUserProfile(tokenResponse.access_token);
        console.log("User profile fetched:", userProfile);
        const response = await loginUser(userProfile);
        console.log("User logged in:", response);
  
        localStorage.setItem('userId', response.user_id);
        localStorage.setItem('userName', userProfile.given_name);
        localStorage.setItem('userImage', userProfile.picture);
        localStorage.setItem('userFullName', userProfile.name);
        localStorage.setItem('exists', userProfile.exists);
  
        const goalId = sessionStorage.getItem('goalId');
        if (goalId && goalId !== "") {
          await updateGoal(response.user_id, goalId);
          console.log('Goal updated successfully');
        }
  
        if (!response.exists) {
          setIsModalVisible(true);
        } else {
          setTimeout(() => navigate('/home'), 100);
        }
      } catch (error) {
        console.error("Error during login process:", error);
      }
    },
    onError: () => console.log("Login failed"),
  });

  const handleCheckboxChange = (event) => {
    setAcceptedTerms(event.target.checked);
    const userId = sessionStorage.getItem('userId');
    
    if (userId) {
      acceptTerms(userId)
        .then(() => {
          setTimeout(() => navigate('/home'), 100);
        })
        .catch((error) => {
          console.error("Failed to accept terms:", error);
        });
    } else {
      console.error("User ID not found in session storage. Please log in again.");
      // Optionally navigate to login or show an error message
    }
  };

  const handleConfirm = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await acceptTerms(userId);
      setIsModalVisible(false);
      navigate('/home');
    } catch (error) {
      console.error('Error accepting terms:', error);
    }
  };

  // Toggle between display and edit mode
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  // Update assistantMessage on text change
  const handleMessageChange = (event) => {
    setAssistantMessage(event.target.value);
  };

  // Save changes and exit edit mode
  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const handleGetStartedClick = () => {
    if (tryItOutRef.current) {
      tryItOutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.target === tryItOutRef.current && entry.isIntersecting) {
            startTypingEffect();
          } else if (stepsRef.current.includes(entry.target)) {
            setStepInView((prev) => {
              const newSteps = [...prev];
              newSteps[index] = entry.isIntersecting;
              return newSteps;
            });
          }
        });
      },
      { threshold: 0.5 }
    );
  
    stepsRef.current.forEach((step) => {
      if (step instanceof Element) {
        observer.observe(step);
      }
    });
    if (tryItOutRef.current instanceof Element) {
      observer.observe(tryItOutRef.current);
    }
  
    return () => {
      // Only unobserve if the elements are still present
      stepsRef.current.forEach((step) => {
        if (step instanceof Element) {
          observer.unobserve(step);
        }
      });
      if (tryItOutRef.current instanceof Element) {
        observer.unobserve(tryItOutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setTypedText(""); 
    setInputVisible(false);

    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setTypedText(fullText.slice(0, index + 1)); 
        index++;
      } else {
        clearInterval(interval);
        setInputVisible(true); 
      }
    }, 50);
    return () => clearInterval(interval);
  }, [fullText]);

  const handleProjectEnter = async (event) => {
    if (event.key === "Enter") {
      moveToStep2();
    }
  };

  const startTypingEffect = () => {
    setTypedText("");
    setInputVisible(false);

    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setTypedText(fullText.slice(0, index + 1));
        index++;
      } else {
        clearInterval(interval);
        setInputVisible(true);
      }
    }, 50);
  };

  const handleProjectInputChange = (event) => {
    setProjectName(event.target.value);
    setIsTyping(event.target.value.length > 0); // Show "Next" button only when input is non-empty
  };

  const moveToStep2 = async () => {
    setProjectName(document.querySelector('.project-input').value);
    try {
      const response = await createNewGoal("", projectName);
      // Store goal_id in session storage
      sessionStorage.setItem("goalId", response.goal_id);
    } catch (error) {
      console.error("Failed to create goal and store goal_id:", error);
    }
    setInputVisible(false); 
    setTypedText(""); 
    setPrompt("Do you have a target date for this?");
    setShowDateInput(true); 
    setCurrentStep(2);
    setHeadingVisible(false); // Trigger fade-out for the heading
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value
    setDueDate(selectedDate); 
  };


  const handleDateNextClick = async () => {
    console.log("Date at next step:", dueDate); // Log date before using it
    setShowDateInput(false); 
    setTypedText(""); 
    setPrompt(`What’s one thing you’ve recently achieved or are working toward on ${projectName}?`);
    setCurrentStep(3);
  
    const goalId = sessionStorage.getItem("goalId");
  
    try {
      await updateGoal(null, goalId, null, null, null, null, dueDate, null)
      console.log("Goal updated successfully.");
    } catch (error) {
      console.error("Failed to update goal:", error);
    }
  };

  const handleProgressChange = (event) => {
    setProgressDescription(event.target.value);
  };

  const handleProgressNextClick = () => {
    console.log("Progress submitted:", progressDescription);
  };



  const handleUpdateNextClick = async () => {
    setTypedText(""); 
    setPrompt(`We cleaned up your entry, how does it look?`);
    setCurrentStep(4);

    // Retrieve goalId from session storage
    const goalId = sessionStorage.getItem("goalId");

    try {
      // Call the submitMessage service with necessary parameters
      const response = await submitMessage(progressDescription, "", goalId);
      
      /// Extract assistant_message from the response and save it to the assistantMessage state
      setAssistantMessage(response.assistant_message);
      setUpdateId(response.update_id)
      sessionStorage.setItem("updateId", response.update_id);

      console.log("Message posted successfully:", assistantMessage);
  } catch (error) {
      console.error("Failed to update goal:", error);
  }
  };

  const handleEditMessage = async () => {
    setTypedText(""); 
    setPrompt(`We cleaned up your entry, how does it look?`);
    setCurrentStep(4);

    // Retrieve goalId from session storage
    const goalId = sessionStorage.getItem("goalId");

    try {
      // Call the submitMessage service with necessary parameters
      const response = await submitMessage(progressDescription, "", goalId);
      
      /// Extract assistant_message from the response and save it to the assistantMessage state
      setAssistantMessage(response.assistant_message);

      console.log("Message posted successfully:", assistantMessage);
  } catch (error) {
      console.error("Failed to update goal:", error);
  } 
  };

  const handleConfirmClick = async () => {
    // Retrieve necessary information from local storage or state
    const updateId = sessionStorage.getItem('updateId'); // Assuming `updateId` is stored in session storage
    const message = assistantMessage; // Ensure `assistantMessage` is updated with the latest content
    console.log(updateId)
    try {
      // Call the finalMessage service with userId, message, and updateId
      const response = await finalMessage("", message, updateId);
  
      console.log('Final message sent successfully:', response);
      // Optionally, you could set some state or give feedback to the user here
      // For example, clear the assistant message or show a success notification
    } catch (error) {
      console.error('Failed to send final message:', error);
      // Optionally, handle errors here, like showing an error notification to the user
    } finally {
      // Call login regardless of success or failure
      login();
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <img src={`${process.env.PUBLIC_URL}/entrovLogo.png`} alt="Entrov Logo" className="logo" />
          <div className="nav-buttons">
            <button className="btn-signin" onClick={login}>Sign In</button>
            <button className="btn-get-started" onClick={handleGetStartedClick}>Try it out</button>
          </div>
        </nav>
        <main className="container">
          <section className="left-content">
            <h1>Effortless Updates, <br />Stronger Relationships</h1>
            <p>Entrov turns quick updates into polished summaries, saving time for client services teams and solo professionals to focus on what matters.</p>
            <button className="btn-primary" onClick={handleGetStartedClick}>Try it out -&gt;</button>
          </section>
          <section className="right-content">
            <img src={`${process.env.PUBLIC_URL}/landingImage.svg`} alt="Illustration" />
          </section>
        {/* Terms and Conditions Modal */}
        <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
          <h2 className="modal-header">Terms and Conditions</h2>
          <p>Please read and accept the terms and conditions before proceeding:</p>
          <div className="terms-box">
            <iframe
              title="Terms and Conditions"
              src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0837ff33-fe21-452c-89ae-a30f630a4ccc"
              frameBorder="0"
              style={{ width: '100%', height: '300px', border: 'none' }}
              scrolling="yes"
            ></iframe>
          </div>
          <label>
            <input type="checkbox" checked={acceptedTerms} onChange={handleCheckboxChange} />
            I accept the terms and conditions
          </label>
          <div className="button-container">
            <button className="next-button" onClick={handleConfirm} disabled={!acceptedTerms}>
              Confirm
            </button>
          </div>
        </Modal>
        
        </main>
        
        <section className="impact-banner">
            <img src={`${process.env.PUBLIC_URL}/banner-blank.svg`} alt="Impact Banner Background" className="impact-banner-bg" />
            <div className="impact-banner-content">
              <h2>Save Time, Make Your Impact Seen</h2>
              <p>With Entrov’s AI-powered platform, spend less time on updates and more on what truly matters.</p>
              <div className="how-it-works">
                {[
                  {
                    title: 'Quick Update Entry',
                    text: 'Enter text, voice, file, or screenshot based updates - Entrov captured your progress.',
                    icon: `${process.env.PUBLIC_URL}/icons/details.svg`
                  },
                  {
                    title: 'AI Summarization',
                    text: 'Automatically refine updates into clear, client-ready summaries in seconds.',
                    icon: `${process.env.PUBLIC_URL}/icons/update.svg`
                  },
                  {
                    title: 'Share with Impact',
                    text: 'Provide polished updates that showcase progress and drive client engagement.',
                    icon: `${process.env.PUBLIC_URL}/icons/happy.svg`
                  }
                ].map((step, index) => (
                  <div
                    key={index}
                    ref={(el) => (stepsRef.current[index] = el)}
                    className={`how-step ${stepInView[index] ? 'slide-in' : 'slide-out'}`}
                  >
                    <img src={step.icon} alt={`${step.title} icon`} className="step-icon" />
                    <h3>{step.title}</h3>
                    <p>{step.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>


        {/* Try It Out Section with Typing Effect */}
        <section ref={tryItOutRef} className="try-it-fullscreen">
          <h2 className={`try-it-heading ${headingVisible ? '' : 'fade-out'}`}>Let's try it out!</h2>
          <p className="typed-text">{typedText}</p>
          
          {inputVisible && currentStep === 1 && (
            <>
              <input
                type="text"
                placeholder="Enter a project name or goal here"
                className="chatgpt-style-input project-input"
                onChange = {handleProjectInputChange}
                onKeyPress={handleProjectEnter} 
              />
              {isTyping && (
              <button className="btn-next" onClick={moveToStep2}>
                Next
              </button>
              )}
            </>
          )}

          {inputVisible && showDateInput && currentStep === 2 && (
            <>
              <input
                type="date"
                className="chatgpt-style-input-date-input"
                onChange={handleDateChange}
                value={dueDate} 
              />
              {dueDate && (
                <button className="btn-next" onClick={handleDateNextClick}>
                  Next
                </button>
              )}
            </>
          )}

          {inputVisible && currentStep === 3 && (
            <>
              <textarea
                placeholder="Think about small wins, or challenges you're tackling right now."
                className="chatgpt-style-input"
                rows="3"
                value={progressDescription}
                onChange={handleProgressChange}
              />
              {progressDescription && (
                <button className="btn-next" onClick={handleUpdateNextClick}>
                  Next
                </button>
              )}
            </>
          )}

          {currentStep === 4 && (
          <>             
              <div className="assistant-message-container">
                {isEditing ? (
                  <textarea
                    type="text"
                    value={assistantMessage}
                    onChange={handleMessageChange}
                    className="assistant-message-input"
                  />
                ) : (
                  <p className="assistant-message-text">{assistantMessage}</p>
                )}
                
                <div className="assistant-message-btns">
                  {isEditing ? (
                    <button className="edit-btn" onClick={handleSaveClick}>Save</button>
                  ) : (
                    <button className="edit-btn" onClick={handleEditClick}>edit</button>
                  )}
                  <button className="confirm-btn" onClick={handleConfirmClick}>Looks good →</button>
                </div>
              </div>
          </>
          )}
        </section>
      </header>
    </div>
  );
}

export default LandingPage;
