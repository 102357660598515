// Home.js
import React, { useState, useEffect } from 'react';
import GoalCard from '../../components/GoalCard/GoalCard';
import SummarizeProgress from '../../components/SummarizeProgress/SummarizeProgress';
import { fetchUserGoals } from '../../services/goalService';
import Modal from '../../components/Modal/Modal';
import './Home2.css';

function Home() {
  const [goals, setGoals] = useState([]);
  const [isAddingNewGoal, setIsAddingNewGoal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSummarizeProgressOpen, setIsSummarizeProgressOpen] = useState(false);
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName');
  const [userMessage, setUserMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("update");

  // Refactor loadGoals so it can be reused
  const loadGoals = async () => {
    try {
      const fetchedGoals = await fetchUserGoals(userId, "False");
      
      // Sort goals by created_date, newest first
      const sortedGoals = fetchedGoals.sort((a, b) => {
        return new Date(b.created_date) - new Date(a.created_date);
      });

      setGoals(sortedGoals); // Set sorted goals to state
    } catch (error) {
      console.error('Error loading goals:', error);
    }
  };


  // Load goals on page load
  useEffect(() => {
    if (userId) {
      loadGoals();
    }
  }, [userId]);

  const handleSubmit = () => {
    if (userMessage.trim()) {
      console.log("User's update:", userMessage);
      setUserMessage('');
    }
  };


  return (
    <>
      <div className="home-page">
        <div className="home-content">
          <div className="greeting-section">
            <h2>
              <span className="greeting-text">Hello, </span><br />
              <span className="user-name">{userName} 👋</span>
            </h2>
          </div>

          <div className="goals-header">
            <h2 className="goals-heading">Your Goals</h2>
            <button
              className="add-new-goal-btn"
              onClick={() => setIsAddingNewGoal(true)}
            >
              Add New
            </button>
            <button
              className="summarize-progress-btn"
              onClick={() => setIsSummarizeProgressOpen(true)}
            >
              Summarize Progress
            </button>
          </div>

          <div className="goal-list-container">
            {isAddingNewGoal && (
              <div className="new-goal-container">
                <GoalCard
                  isNewGoal={true}
                  onSave={(newGoal) => {
                    setIsAddingNewGoal(false); // Close the add new goal form
                    loadGoals(); // Reload goals to get the latest data
                  }}
                  onDelete={() => setIsAddingNewGoal(false)}
                  initialGoal={{ title: '', description: '', due_date: '', progress_bar: 0 }}
                />
              </div>
            )}

            <div className="goals-section">
              {goals.map((goal) => (
                <GoalCard
                  key={goal.goal_id}
                  isNewGoal={false}
                  initialGoal={goal}
                  onSave={(updatedGoal) => {
                    const updatedGoals = goals.map(g => g.goal_id === updatedGoal.goal_id ? updatedGoal : g);
                    setGoals(updatedGoals);
                  }}
                  onDelete={() => {
                    const updatedGoals = goals.filter(g => g.goal_id !== goal.goal_id);
                    setGoals(updatedGoals);
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        {showSuccessMessage && (
          <div className="success-dialog">
            <p>Submission successful!</p>
          </div>
        )}

        <Modal
          isVisible={isSummarizeProgressOpen}
          onClose={() => setIsSummarizeProgressOpen(false)}
        >
          <SummarizeProgress userId={userId} />
        </Modal>
      </div>
    </>
  );
}

export default Home;
