import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home2/Home2';
import Onboarding from './pages/Onboarding/Onboarding';
import Goals from './pages/Goals/Goals';
import LandingPage from './pages/LandingPage/LandingPage';
import TermsAndConditions from './pages/Terms/Terms';
import ProgressSummary from './pages/ProgressSummary/ProgressSummary';
import Sidebar from './components/Sidebar/Sidebar';
import PrivacyPolicy from './components/Documents/PrivacyPolicy';
import TermsPage from './components/Documents/Terms';


import './index.css'; // Assuming you have a CSS file for global styles

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} /> {/* Default route to LandingPage */}
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/goals" element={<Layout><Goals /></Layout>} />
        <Route path="/summary/:goalId" element={<Layout><ProgressSummary /></Layout>} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsPage/>} />
        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect unknown routes */}
      </Routes>
    </Router>
  );
}

function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="app-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> {/* Pass state to Sidebar */}
      <div className={`content ${isSidebarOpen ? '' : 'collapsed'}`}>
        {children} {/* Render the page content */}
      </div>
    </div>
  );
}

export default App;
