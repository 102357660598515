import React from 'react';
import './ContactUs.css'; // Make sure to keep your CSS import

function ContactUs() {
  return (
    <div className="contact-us-modal">
      <h2>We'd love to help you out</h2>
      <p>You can reach us at <a href="mailto:support@entrov.com">support@entrov.com</a></p>
    </div>
  );
}

export default ContactUs;
