import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PREFIX;
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;

// Function to log in or create a user
export const fetchProgressReport = async (userId, goalId, forceUpdate = "true", start = null,end = null) => {
  try {
    const response = await axios.post(`${API_URL}/goals/progress_report/`, {
      user_id: userId,  // UserID from passed variable
      goal_id: goalId,  // GoalID from passed variable
      force_update: forceUpdate, // True or false, true returns new version. False returns most recent version
      start_date: start, // start date to pull updates from
      end_date: end // end date of pulling updates from
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};


// Function to log in or create a user
export const saveProgressReport = async (userId, reportId, message) => {
    try {
      const response = await axios.post(`${API_URL}/goals/progress_report/`, {
        user_id: userId,  // UserID from passed variable
        progress_report_id: reportId,  // reportId from passed variable
        message:message
      },
      {
        headers: {
          'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };