import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewGoal } from '../../services/goalService';
import { acceptTerms } from '../../services/profileService';
import GoalCard from '../../components/GoalCard/GoalCard';
import Modal from '../../components/Modal/Modal';
import { handleTitleChange, handleDescriptionChange, handleDateChange, deleteGoal, addGoal } from '../../utils/goalHandlers';
import './Onboarding.css';

const OnboardingPage = () => {
  const [newGoals, setNewGoals] = useState([{ id: 1, title: '', description: '', due_date: '', progress_bar: 0 }]);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isGoalExpanded, setIsGoalExpanded] = useState(false); // Track if any goal card is expanded
  const navigate = useNavigate();

  const handleNextClick = () => {
    setIsModalVisible(true);
  };

  const handleCheckboxChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  const handleSaveGoals = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const nonDeletedGoals = newGoals.filter(goal => goal.title || goal.description || goal.due_date);

      const responses = await Promise.all(nonDeletedGoals.map(goal =>
        createNewGoal(userId, goal.title, goal.description, goal.due_date, goal.progress_bar)
      ));

      const allSuccess = responses.every(response => response.success);

      if (allSuccess) {
        setMessage('All goals created successfully!');
        setMessageType('success');
        setNewGoals([{ id: 1, title: '', description: '', due_date: '' }]);

        setTimeout(() => {
          setIsModalVisible(false);
          navigate('/home');
        }, 1000);
      } else {
        setMessage('Some goals failed to create. Please try again.');
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error creating new goals:', error);
      setMessage('An error occurred while creating goals.');
      setMessageType('error');
    }
  };

  const handleConfirm = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await acceptTerms(userId);
      await handleSaveGoals();
      setIsModalVisible(false);
      navigate('/home2');
    } catch (error) {
      console.error('Error accepting terms or saving goals:', error);
      setMessage('An error occurred while accepting terms or saving goals.');
      setMessageType('error');
    }
  };

  return (
    <div className="onboarding-page">
      <header className="App-header">
        <div className="navbar">
          <img src={`${process.env.PUBLIC_URL}/entrovLogo.png`} alt="Entrov Logo" className="logo" />
        </div>
      </header>

      <h1>{isGoalExpanded ? 'Awesome, now let\'s add some details.' : 'Let\'s get you started!'}</h1>
      <p>{isGoalExpanded ? 'You can add a description, due date, and set current progress.' : 'Enter a title for an outcome, project, or even client name you\'re working with.'}</p>

      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}

      {newGoals.map((goal, index) => (
        <GoalCard
          key={index}
          initialGoal={goal}
          onTitleChange={(id, value) => handleTitleChange(id, value, newGoals, setNewGoals)}
          onDescriptionChange={(id, value) => handleDescriptionChange(id, value, newGoals, setNewGoals)}
          onDateChange={(id, value) => handleDateChange(id, value, newGoals, setNewGoals)}
          onDelete={() => deleteGoal(goal.id, newGoals, setNewGoals)}
          isNewGoal={true}
          onExpand={(expanded) => setIsGoalExpanded(expanded)} // Track if the goal card is expanded
        />
      ))}

      <button className="next-button" onClick={handleNextClick}>Next</button>

      <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <h2 className="modal-header">Terms and Conditions</h2>
        <p>Please read and accept the terms and conditions before proceeding:</p>

        <div className="terms-box">
          <iframe
            title="Terms and Conditions"
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0837ff33-fe21-452c-89ae-a30f630a4ccc"
            frameBorder="0"
            style={{ width: '100%', height: '300px', border: 'none' }}
            scrolling="yes"
          ></iframe>
        </div>

        <label>
          <input type="checkbox" checked={acceptedTerms} onChange={handleCheckboxChange} />
          I accept the terms and conditions
        </label>
        <div className="button-container">
          <button className="next-button" onClick={handleConfirm} disabled={!acceptedTerms}>
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OnboardingPage;
